<template>
    <div class="content-wrapper">
      <!-- <div v-if="loader" class="page_loader"></div> -->
      <!-- Content Header (Page header) -->
      <div class="content-header" style="font-size:16px;">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Operational Dashboard</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">{{ $t('globalTrans.home') }}</a></li>
                <li class="breadcrumb-item active">Operational Dashboard</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid dashboard-card">
          <div class="card">
            <div class="card-header">
              <div class="row">
                   <div class="col-md-2">
                        <div class="form-group">
                            <label for="tracking_id">{{ $t('globalTrans.order_id') }}</label>
                            <input type="text" id="tracking_id" class="form-control" placeholder="Order ID" v-model="orderSearch.tracking_id">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="contact">{{ $t('globalTrans.contact') }}</label>
                            <input type="text" id="contact" class="form-control" placeholder="Name, Mobile, Address" v-model="orderSearch.contact">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="reference_id">{{ $t('globalTrans.reference_id') }}</label>
                            <input type="text" id="reference_id" class="form-control" placeholder="Reference ID" v-model="orderSearch.reference_id">
                        </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group" style="margin-top:30px;">
                          <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchOrder"><i class="fa fa-search"></i> Filter</button>
                          <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                      </div>
                  </div>
              </div>
            </div>
            <div class="card-body">
              <b-overlay :show="orderLoader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                      <thead style="width:100%">
                          <tr>
                              <th>Order ID</th>
                              <th>Customer Name</th>
                              <th>Mobile No</th>
                              <th>Order Date</th>
                              <th>Status</th>     
                              <th>Payment</th>                                     
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(order,key) in orders" :key="key">
                              <td @click="orderLog(order.logs)">{{ order.tracking_id }}</td>
                              <td>{{ order.name }}</td>
                              <td>{{ order.mobile }}</td>
                              <td>{{ order.date }}</td>
                              <td><span :class="'status-'+order.status">{{ getStatus(order.status) }}</span></td>                                         
                              <td><span :class="'payment-status-'+order.payment_status">{{ order.payment_status != 0 ? getPaymentStatus(order.payment_status) : '' }}</span></td>                                         
                          </tr>
                      </tbody>
                  </table>
                </div>
              </b-overlay>
            </div>
          </div>

          <div class="card mt-20">
            <div class="card-header">
              <div class="row">
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <label for="start_date">{{ $t('globalTrans.startDate') }} </label>
                          <input type="date" id="start_date" v-model="summarySearch.start_date" class="form-control" />
                      </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <label for="end_date">{{ $t('globalTrans.endDate') }} </label>
                          <input type="date" id="end_date" v-model="summarySearch.end_date"  class="form-control" />
                      </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                    <div class="form-group">
                        <label for="title">{{ $t('globalTrans.hub') }}</label>
                        <v-select name="hub_id"
                          v-model="summarySearch.hub_id"
                          label="text"
                          :reduce="item => item.id"
                          :options= hubList
                          :placeholder="$t('globalTrans.select')"
                        />
                    </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                    <div class="form-group">
                        <label for="title">{{ $t('globalTrans.rider') }}</label>
                        <v-select name="rider_id"
                          v-model="summarySearch.rider_id"
                          label="text"
                          :reduce="item => item.id"
                          :options= riderList
                          :placeholder="$t('globalTrans.select')"
                        />
                    </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchSummaryData" style="margin-top:30px;"><i class="fa fa-search"></i> Filter</button>
                      </div>
                  </div>
              </div>
            </div>
            <div class="card-body">              
              <b-overlay :show="loader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                      <thead style="width:100%">
                          <tr>
                              <th colspan="8"><h4>Today's Summary</h4></th>
                              <th><h4>ARPU</h4></th>
                              <th><h4>{{ todayData ? todayData.arpu : 0 }}</h4></th>
                          </tr>
                          <tr>
                              <th>Total Orders</th>
                              <th>Growth Ratio</th>
                              <th>New</th>
                              <th>Previous</th>
                              <th>Total Assign</th>
                              <th>Assigned From Previous Pending</th>
                              <th>Assign Pending</th>
                              <th>Pick Request</th>
                              <th>Return Pending</th>
                              <th>Delivery Agent</th>
                          </tr>
                      </thead>
                      <tbody v-if="todayData" class="text-center">
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ today +'&end_date='+ today">
                              {{ todayData.total_order }}
                            </router-link>
                          </td>
                          <td>{{ todayData.growth_ratio }}</td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ today +'&end_date='+ today">
                              {{ todayData.new_order }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ yesterday +'&end_date='+ yesterday">
                              {{ todayData.previous_order }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ today +'&end_date='+ today + '&status=7&status=11'">
                              {{ todayData.total_assign }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ yesterday +'&end_date='+ yesterday + '&activity_date='+ today + '&status=7&status=11'">
                              {{ todayData.assigned_from_prev_pending }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ today +'&end_date='+ today + '&status[]=10'">
                              {{ todayData.assign_pending }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ today +'&end_date='+ today + '&status[]=1'">
                              {{ todayData.pick_requested }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ today +'&end_date='+ today + '&status=20&status=21&status=22&status=23&status=24'">
                              {{ todayData.return_pending }}
                            </router-link>
                          </td>
                          <td>{{ todayData.delivery_agent }}</td>
                      </tbody>
                  </table>
                </div>
              </b-overlay>

              <b-overlay :show="loader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                      <thead style="width:100%">
                          <tr>
                              <th colspan="9"><h4>Lastday/Custom Summary</h4></th>
                              <th><h4>ARPU</h4></th>
                              <th><h4>{{ lastdayData ? lastdayData.arpu : 0 }}</h4></th>
                          </tr>
                          <tr>
                              <th>Total Orders</th>
                              <th>Total Delivered</th>
                              <th>Growth Ratio</th>
                              <th>Total Cancel</th>
                              <th>Total Hold</th>
                              <th>Total Reschedule</th>
                              <th>Collected Amount</th>
                              <th>Pick Pending</th>
                              <th>Return Pending</th>
                              <th>Delivery Agent</th>
                              <th>Edited ID</th>
                          </tr>
                      </thead>
                      <tbody v-if="lastdayData">
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ summarySearch.start_date +'&end_date='+ summarySearch.end_date">
                              {{ lastdayData.total_order }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ summarySearch.start_date +'&end_date='+ summarySearch.end_date + '&status=13&status=14'">
                              {{ lastdayData.total_done }}
                            </router-link>
                          </td>
                          <td>{{ lastdayData.growth_ratio }}</td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ summarySearch.start_date +'&end_date='+ summarySearch.end_date + '&status=12&status=20'">
                              {{ lastdayData.total_cancel }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ summarySearch.start_date +'&end_date='+ summarySearch.end_date + '&status[]=17'">
                              {{ lastdayData.total_hold }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ summarySearch.start_date +'&end_date='+ summarySearch.end_date + '&status[]=18'">
                              {{ todayData.total_reschedule }}
                            </router-link>
                          </td>
                          <td>{{ todayData.collected_amount }}</td>
                          <td>{{ lastdayData.pick_pending }}</td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ summarySearch.start_date +'&end_date='+ summarySearch.end_date + '&status=20&status=21&status=22&status=23&status=24'">
                              {{ lastdayData.return_pending }}
                            </router-link>  
                          </td>
                          <td>{{ lastdayData.delivery_agent }}</td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ summarySearch.start_date +'&end_date='+ summarySearch.end_date + '&parameter=edited'">
                              {{ lastdayData.editedid }}
                            </router-link>
                          </td>
                      </tbody>
                  </table>
                </div>
              </b-overlay>
            </div>
          </div>

          <div class="card mt-20">
            <h2 class="card-title entry-summary-details">Entry Summary Details </h2>   
            <div class="card-header">
              <div class="row">
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <label for="date">{{ $t('globalTrans.startDate') }} </label>
                          <input type="date" id="date" v-model="entrymanSearch.start_date" class="form-control" />
                      </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <label for="date">{{ $t('globalTrans.endDate') }} </label>
                          <input type="date" id="date" v-model="entrymanSearch.end_date" class="form-control" />
                      </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                    <div class="form-group">
                        <label for="title">Entryman</label>
                        <v-select name="user_id"
                          v-model="entrymanSearch.user_id"
                          label="text"
                          :reduce="item => item.id"
                          :options= userList
                          :placeholder="$t('globalTrans.select')"
                        />
                    </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchEntrymanDetails" style="margin-top:30px;"><i class="fa fa-search"></i> Filter</button>
                          <button  class="btn btn-secondary btn-sm ml-2" style="margin-top:30px;">Total: {{ entrySummaryTotal }}</button>
                      </div>
                  </div>
              </div>
            </div>
            <div class="card-body">           
              <b-overlay :show="entryManSummaryLoader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm text-center">
                      <thead style="width:100%">
                          <tr>
                              <th>SL</th>
                              <th>Merchcant </th>
                              <th>Total Parcel</th>
                          </tr>
                      </thead>
                      <tbody class="text-center">
                          <tr v-for="(item, index) in merchantSummary" :key="index">
                            <td>{{ index+1 }}</td>
                            <td>{{ getMerchantName(item.merchant_id) }}</td>
                            <td>{{ item.total_order }}</td>
                          </tr>
                      </tbody>
                  </table>
                </div>
              </b-overlay>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
      
      <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header bg-info text-white">
                      <h5 class="modal-title text-center w-100">Order Log</h5>
                      <button type="button" class="close" aria-label="Close" @click="cancelModal">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <table class="table table-bordered table-sm">
                          <tr>
                              <th style="width:30%">Action By</th>
                              <th>Action</th>
                              <th>Date & Time</th>
                          </tr>
                          <tr v-for="(log, index) in order_logs" :key="index">
                              <td>{{ log.operation_by }}</td>
                              <td>{{ log.operation }}</td>
                              <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                          </tr>
                      </table>                      
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
  import config from '@/config'
  import moment from 'moment'

  export default {
    name:'OperationDashboard',
    data() {
      return {
        orderLogModal:false,
        entryManSummaryLoader:false,
        yesterday: moment().subtract(1,'d').format('YYYY-MM-DD'),
        today: moment().format('YYYY-MM-DD'),
        orderLoader: false,
        loader: false,
        orders: [],
        orderSearch : {
          tracking_id: '',
          mobile: '',
          reference_id: ''
        },
        entrySummaryTotal: 0,
        entrymanSearch : {
          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          user_id: ''
        },
        merchantSummary: [],
        order_logs: [],
        summarySearch : {
            hub_id: '',
            rider_id: '',
            start_date: moment().subtract(1,'d').format('YYYY-MM-DD'),
            end_date: moment().subtract(1,'d').format('YYYY-MM-DD')
        },
        orderData: [],
        todayData: null,
        lastdayData: null
      }
    },
    created () {
      this.searchSummaryData()
    },
    computed : {
      hubList () {
        return this.$store.state.commonObj.hubList
      },
      riderList () {
        return this.$store.state.commonObj.riderList
      },
      merchantList () {
        return this.$store.state.commonObj.merchantList
      },
      userList () {
        return this.$store.state.commonObj.userList.filter(el => el.role_id === 6) // 6 mean entry man
      },
      authUser () {
        return this.$store.state.authUser
      }
    },
    methods: {
      reload () {
          this.orderSearch = Object.assign({}, {
            tracking_id: '',
            mobile: '',
            reference_id: ''
          })
      },
      searchOrder () {
        this.getOrderData()
      },
      searchSummaryData () {
        this.getTodaySummary()
        this.getLastdaySummary()
      },
      searchEntrymanDetails () {
        this.getEntrySummaryDetails()
        this.getEntryManSummary()
      },
      async getOrderData () {
        this.orderLoader = true
        const params = this.orderSearch
        const response = await config.getData('/dashboard/track', params)
        this.orderLoader = false
        if (response.status == 200) {
            this.orders = response.data  
        } else {
            this.orders = []
        }    
      },
      getTodaySummary () {
        this.loader = true
        const params = this.summarySearch
        config.getData('/dashboard/operational-today-summary', params)
        .then((response) => {
          this.loader = false
          this.todayData = response.data
        });
      },
      getLastdaySummary () {
        this.loader = true
        const params = this.summarySearch
        config.getData('/dashboard/operational-lastday-summary', params)
        .then((response) => {
          this.loader = false
          this.lastdayData = response.data
        });
      },
      getEntrySummaryDetails () {
        this.entryManSummaryLoader = true
        config.getData('/dashboard/entryman-summary-details', this.entrymanSearch)
        .then((response) => {
          this.entryManSummaryLoader = false
          this.merchantSummary = response.data
        });
      },
      async getEntryManSummary () {
        this.loader = true
        const response = await config.getData('/dashboard/entryman-summary', this.entrymanSearch)
        this.loader = false
        this.entrySummaryTotal = response.data.total_order        
      },
      getStatus (StatusId) {
        const tmpStatus = this.$store.state.statusList.find(status => status.id == StatusId)
        return this.$t(tmpStatus.text)
      },
      getPaymentStatus (paymentStatus) {
        if (paymentStatus == 1) {
            return 'Due'
        } else if (paymentStatus == 2) {
            return 'Processing'
        } else if (paymentStatus == 3) {
            return 'Paid'
        } else {
            ''
        }
      },
      orderLog (logs) {
          this.order_logs = logs 
          this.orderLogModal = true
      },
      cancelModal () {
          this.orderLogModal = false
      },
      getMerchantName (merchantId) {
        const merchant = this.merchantList.find(el => el.id === merchantId)
        return merchant ? merchant.text : ''
      }
    }
  }
</script>

<style scoped>
.entry-summary-details {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}
</style>